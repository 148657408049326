<template>
  <div class="defaultListing">
    <DesignPage />
  </div>
</template>

<script>
import DesignPage from "@/components/DesignPage";

export default {
  name: "defaultListing",
  components: { DesignPage },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  created() {
    this.$store.dispatch("design/getDesignData", this.routeName);
  },
};
</script>
